import { FirebaseApp } from "./firebase";
import { sleep } from "./utils";

export interface Letter {
  label: string;
  description: string;
  emoji: string;
  reasons: Array<{
    header: string;
    paragraph: string;
  }>;
  links: Array<{
    link: string;
  }>;
}

const _createLetter = FirebaseApp.functions.httpsCallable("createLetter");

export const createLetter = async (letter: Letter) => {
  const response = await _createLetter(letter);
  return response;
};

export const retrieveLetter = async (docId: string) => {
  const cached_doc = retrieveCachedLetter(docId);
  if (cached_doc) {
    sleep(100);
    return { status: 200, data: cached_doc };
  }

  const docRef = FirebaseApp.db.collection("letters").doc(docId);
  const doc = await docRef.get();
  if (doc.exists) {
    const data = doc.data();
    cacheLetter(docId, data);
    return { status: 200, data: data as Letter };
  }
  return { status: 300, data: {} };
};

export const cacheLetter = (docId: string, docData: any) => {
  let stringified = JSON.stringify(docData);
  window.localStorage.setItem(docId, stringified);
  return true;
};

export const retrieveCachedLetter = (docId: string) => {
  const data = window.localStorage.getItem(docId);
  if (data) {
    return JSON.parse(data);
  }
  return false;
};
