import React, { useState, useEffect } from "react";
import "./App.css";
import {
  IntroMenu,
  CategorySelector,
  LetterMaker,
  EducationalPrompt,
  LetterReader,
} from "./components";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import FinalDialog from "./components/final_dialog";
import { loginAnonymously } from "./core/auth";
import { FirebaseApp } from "./core/firebase";

const Footer = (props: any) => {
  return (
    <div className="intro-footer">
      <p>©2020 Boyfairy Collective</p>
    </div>
  );
};

function App() {
  const [navigatorIndex, setNavigatorIndex] = useState(0);
  const [navigatorData, setNavigatorData] = useState();
  const [user, loading, error] = useAuthState(FirebaseApp.auth);
  if (!user) {
    loginAnonymously();
  }
  return (
    <div className="app">
      <div className="main">
        <div className="intro-header"></div>
        <Router>
          <Switch>
            <Route path="/@/:letterId">
              <LetterReader>
                <Link to="/">
                  <IntroMenu
                    setNavigatorIndex={setNavigatorIndex}
                    isRef={true}
                  />
                </Link>
              </LetterReader>
            </Route>
            <Route path="/">
              {navigatorIndex == 0 && (
                <IntroMenu setNavigatorIndex={setNavigatorIndex} />
              )}
              {navigatorIndex == 1 && (
                <CategorySelector
                  setNavigatorIndex={setNavigatorIndex}
                  setNavigatorData={setNavigatorData}
                />
              )}

              {navigatorIndex == 2 && (
                <LetterMaker
                  setNavigatorIndex={setNavigatorIndex}
                  setNavigatorData={setNavigatorData}
                  navigatorData={navigatorData}
                />
              )}

              {navigatorIndex == 3 && (
                <EducationalPrompt
                  setNavigatorIndex={setNavigatorIndex}
                  navigatorData={navigatorData}
                  setNavigatorData={setNavigatorData}
                />
              )}

              {navigatorIndex == 4 && (
                <FinalDialog
                  setNavigatorIndex={setNavigatorIndex}
                  navigatorData={navigatorData}
                  setNavigatorData={setNavigatorData}
                />
              )}
            </Route>
          </Switch>
        </Router>
        {navigatorIndex != 2 && <Footer />}
      </div>
    </div>
  );
}

export default App;
