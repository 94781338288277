import React from "react";
const IntroMenu = (props: any) => {
  const isRef = props.isRef ?? false;
  return (
    <div className="intro-menu" {...props}>
      {!isRef && (
        <div className="row">
          <span className="intro-emoji">👼</span>
        </div>
      )}
      <div className="row">
        <a
          className="intro-btn attention"
          onClick={() => props.setNavigatorIndex(1)}
        >
          {isRef ? "Send back a boyfairy!" : "Send a boyfairy!"}
        </a>
      </div>
    </div>
  );
};
export default IntroMenu;
