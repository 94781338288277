import React, { useState, useRef, useEffect, Fragment } from "react";
import { TweenMax } from "gsap";

const LetterMakerButton = (props: any) => {
  const letterMakerBtnRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (letterMakerBtnRef.current)
      props.setHeight(letterMakerBtnRef.current?.offsetHeight);
  });
  return (
    <div
      className={
        "letter-maker-btn-container " + (props.attention ? "attention" : "")
      }
      onClick={props.onClick}
    >
      <div
        ref={letterMakerBtnRef}
        className={"letter-maker-btn " + (props.disabled ? "disabled" : "")}
        style={{ minHeight: props.minHeight }}
      >
        <span>+</span>
      </div>
    </div>
  );
};

const LetterMakerBox = (props: any) => {
  const letterMakerBoxRef = useRef<HTMLDivElement>(null);
  const letterMakerRemoveBtnRef = useRef<HTMLDivElement>(null);
  const inputHeaderRef = useRef<HTMLInputElement>(null);
  const inputParagraphRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (letterMakerBoxRef.current) {
      props.setHeight(letterMakerBoxRef.current?.offsetHeight);
      letterMakerBoxRef.current.style.display = "flex";
      TweenMax.fromTo(
        letterMakerBoxRef.current,
        0.5,
        { transform: "scale(0.1)", opacity: 1 },
        { transform: "scale(1)", opacity: 1 }
      );
      TweenMax.fromTo(
        letterMakerRemoveBtnRef.current,
        0.2,
        { transform: "scale(0.4)", opacity: 1 },
        { transform: "scale(1)", opacity: 1 }
      );
    }
    if (inputHeaderRef.current) {
      inputHeaderRef.current.focus();
    }

    return () => {
      if (letterMakerBoxRef.current) {
        TweenMax.fromTo(
          letterMakerBoxRef.current,
          0.5,
          { transform: "scale(1)", opacity: 1 },
          { transform: "scale(0.1)", opacity: 0 }
        );
      }
    };
  }, [letterMakerBoxRef]);

  const handleHeaderInputChange = (evt: any) => {
    if (inputHeaderRef.current) {
      props.updateSelf(props.index, {
        header: evt.target.value,
      });
    }
  };

  const handleParagraphInputChange = (evt: any) => {
    if (inputParagraphRef.current) {
      props.updateSelf(props.index, {
        paragraph: evt.target.value,
      });
    }
  };

  return (
    <div className="letter-maker-box-container">
      <div
        ref={letterMakerBoxRef}
        className="letter-maker-box"
        style={{ minHeight: props.minHeight, display: "none" }}
      >
        <div
          ref={letterMakerRemoveBtnRef}
          className="letter-maker-remove-btn"
          onClick={() => props.removeSelf(props.index)}
        >
          <span>×</span>
        </div>
        <div className="letter-maker-inner">
          <input
            className="headerInput"
            ref={inputHeaderRef}
            placeholder="What's on your mind?"
            onChange={handleHeaderInputChange}
          />
          <input
            className="paragraphInput"
            ref={inputParagraphRef}
            placeholder="Describe what's going on..."
            onChange={handleParagraphInputChange}
          />
        </div>
      </div>
    </div>
  );
};

const LetterMaker = (props: any) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const [currIndex, setCurrIndex] = useState(0);
  const [boxes, setBoxes]: any = useState([]);
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const checkIfSubmitReady = () => {
    let result = boxes.reduce((isPrevNotEmpty: boolean, box: any) => {
      return (
        isPrevNotEmpty ||
        (box.header.trim() != "" && box.paragraph.trim() != "")
      );
    }, false);
    setIsSubmitReady(result);
  };
  const heightSetter = (height: number) =>
    setMaxHeight(Math.max(maxHeight, height));
  const removeBoxAtIndex = (index: number) => {
    const newBoxes = boxes.filter(
      (_: any, box_index: any) => box_index != index
    );
    checkIfSubmitReady();
    setBoxes(newBoxes);
  };
  const updateBoxAtIndex = (index: number, update: any) => {
    let updatedBoxes = boxes;
    updatedBoxes[index] = {
      ...boxes[index],
      ...update,
    };
    setBoxes(updatedBoxes);
    checkIfSubmitReady();
  };

  const addBox = () => {
    if (boxes.length > 4) {
      return;
    }
    setBoxes([
      ...boxes,
      {
        header: "",
        paragraph: "",
        createdAt: new Date().getTime(),
        id: currIndex,
      },
    ]);
    checkIfSubmitReady();
    setCurrIndex(currIndex + 1);
  };

  const handleSubmit = () => {
    if (isSubmitReady) {
      props.setNavigatorData({
        ...props.navigatorData,
        reasons: boxes,
      });
      props.setNavigatorIndex(3);
    }
  };

  const letterMakerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (letterMakerRef.current != null) {
      letterMakerRef.current.style.display = "block";
      TweenMax.fromTo(
        letterMakerRef.current,
        0.5,
        { transform: "scale(0.8)", opacity: 1 },
        { transform: "scale(1)", opacity: 1 }
      );
    }
  }, [TweenMax]);

  const navigatorData = props.navigatorData;
  return (
    <div className="letter-maker-container">
      <div
        ref={letterMakerRef}
        className="letter-maker"
        style={{ display: "none" }}
      >
        <div className="letter-maker-desc">
          <div className="letter-maker-emoji-container">
            <span>{navigatorData.emoji}</span>
          </div>
          <div className="letter-maker-label">
            <p>{navigatorData.description}</p>
          </div>
          <div className="letter-maker-btn-group">
            <a
              className={!isSubmitReady ? "disabled" : ""}
              onClick={handleSubmit}
            >
              Send
            </a>
          </div>
        </div>
        <div className="letter-maker-library">
          <LetterMakerButton
            minHeight={maxHeight}
            setHeight={heightSetter}
            onClick={addBox}
            attention={boxes.length == 0}
            disabled={boxes.length > 4}
          />
          {boxes
            .sort((entry1: any, entry2: any) => entry1.id < entry2.id)
            .map((data: any, index: any) => (
              <LetterMakerBox
                minHeight={maxHeight}
                setHeight={heightSetter}
                removeSelf={removeBoxAtIndex}
                updateSelf={updateBoxAtIndex}
                header={data.header}
                paragraph={data.paragraph}
                index={index}
                key={index}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default LetterMaker;
