import React, { useEffect, useState, useRef, useCallback } from "react";
import { TweenMax } from "gsap";
import { createLetter } from "../core/letter";
import { makeURL } from "../core/makeURL";
//https://stackoverflow.com/questions/43260617/selection-addrange-is-deprecated-and-will-be-removed-from-chrome
const select = (elem: any) => {
  let selection = window.getSelection(); // Save the selection.
  const range = document.createRange();
  range.selectNodeContents(elem);
  selection?.removeAllRanges(); // Remove all ranges from the selection.
  selection?.addRange(range);
};

//https://stackoverflow.com/questions/36639681/how-to-copy-text-from-a-div-to-clipboard
const copy = (elem: any) => {
  let selection = window.getSelection(); // Save the selection.
  var range = document.createRange();
  range.selectNodeContents(elem);
  selection?.removeAllRanges(); // clear current selection
  selection?.addRange(range); // to select text
  document.execCommand("copy");
  selection?.removeAllRanges(); // to de
};

const CopyBtn = (props: any) => {
  const handleClick = () => {
    if (props.linkRef) {
      copy(props.linkRef.current);
      props.setIsCopied(true);
    }
  };
  return (
    <a
      className={
        "final-dialog-copy-btn " +
        (props.copied ? "" : "attention") +
        " " +
        (props.disabled ? "disabled" : "")
      }
      onClick={handleClick}
    >
      {props.disabled ? "Wait!" : props.copied ? "Copied" : "Copy the link"}
    </a>
  );
};

const FinalDialog = (props: any) => {
  const [isCopied, setIsCopied] = useState(false);
  const [letterURL, setLetterURL] = useState("");
  const finalDialogLinkRef = useRef<HTMLDivElement>(null);
  const finalDialogRemoveBtnRef = useRef<HTMLDivElement>(null);
  const finalDialogRef = useRef<HTMLDivElement>(null);

  const close = () => {
    props.setNavigatorData();
    props.setNavigatorIndex(0);
  };

  const createLetterAPI = useCallback(async () => {
    let response = await createLetter(props.navigatorData);
    if (response.data.status == 200) {
      setLetterURL(makeURL(response.data.data));
    }
  }, []);

  useEffect(() => {
    createLetterAPI();
  }, [createLetterAPI]);

  useEffect(() => {
    if (finalDialogLinkRef.current) {
      select(finalDialogLinkRef.current);
    }
    if (finalDialogRef.current) {
      TweenMax.fromTo(
        finalDialogRef.current,
        0.5,
        { transform: "scale(1.4)" },
        { transform: "scale(1)" }
      );
    }
    if (finalDialogRemoveBtnRef.current) {
      TweenMax.fromTo(
        finalDialogRemoveBtnRef.current,
        0.2,
        { transform: "scale(0.4)" },
        { transform: "scale(1)" }
      ).delay(0.4);
    }
  }, [TweenMax]);
  return (
    <div className="final-dialog-container">
      <div ref={finalDialogRef} className="final-dialog">
        <div
          ref={finalDialogRemoveBtnRef}
          className="final-dialog-close-btn"
          onClick={close}
        >
          <span>×</span>
        </div>
        <div>
          <span className="final-dialog-emoji">
            {props.navigatorData.emoji}
          </span>
        </div>
        <div ref={finalDialogLinkRef} className="final-dialog-link">
          {letterURL == "" ? (
            <div className="loadingio-spinner-rolling-92gbw0a1ack">
              <div className="ldio-vm6kcz5uvjm">
                <div></div>
              </div>
            </div>
          ) : (
            <span>{letterURL}</span>
          )}
        </div>
        <div>
          <div className="final-dialog-desc">
            <span>Send boyfairy their way</span>
          </div>
          <div className="final-dialog-footer">
            <CopyBtn
              linkRef={finalDialogLinkRef}
              copied={isCopied}
              setIsCopied={setIsCopied}
              disabled={letterURL == ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalDialog;
