import React, { useState, useEffect, useRef } from "react";
import { isValidUrl } from "../core/utils";
import { TweenMax } from "gsap";

const DummySuggestionsData = [
  {
    label: "Fatphobia",
    links: [
      {
        link:
          "https://www.vice.com/en_in/article/mb4e7n/how-to-treat-fat-people-ally-fatphobia",
      },
      {
        link: "https://thebodyisnotanapology.com/magazine/weight-stigma-101/",
      },
    ],
  },
  {
    label: "Racism",
    links: [
      {
        link:
          "https://www.washingtonpost.com/nation/2020/06/08/understanding-racism-inequality-america/",
      },
      {
        link: "https://www.urban.org/features/structural-racism-america",
      },
    ],
  },
  {
    label: "Objectification",
    links: [
      {
        link:
          "https://medium.com/@starkraving/how-not-to-objectify-women-2830b9696734",
      },
      {
        link: "https://www.quora.com/How-can-I-stop-objectifying-women-1",
      },
    ],
  },
  {
    label: "Stalking",
    links: [
      {
        link: "https://www.protectamerica.com/reclaiming-your-space",
      },
      {
        link:
          "https://thriveglobal.com/stories/why-social-media-stalking-is-ruining-your-life/",
      },
    ],
  },
  {
    label: "Male Privilege",
    links: [
      {
        link: "https://www.youtube.com/watch?v=X3yvGaMQ2Jg",
      },
      {
        link: "https://www.youtube.com/watch?v=lrYx7HaUlMY",
      },
    ],
  },
  {
    label: "Offensive Language",
    links: [
      {
        link:
          "https://www.quora.com/Is-it-OK-to-say-bad-words-during-an-argument-with-your-loving-partner",
      },
      {
        link:
          "https://www.modernmom.com/what-does-it-mean-when-your-partner-curses-at-you/",
      },
    ],
  },
  {
    label: "Trauma",
    links: [
      {
        link:
          "https://www.acesconnection.com/blog/how-to-support-someone-like-me-who-has-experienced-trauma",
      },
      {
        link:
          "https://www.psychologytoday.com/us/blog/rediscovering-love/201603/when-trauma-disrupts-love",
      },
    ],
  },
  {
    label: "Emotional Manipulation",
    links: [
      {
        link:
          "https://peaksrecovery.com/blog/effects-of-psychological-emotional-manipulation",
      },
      {
        link: "https://www.psychologies.co.uk/tests/are-you-manipulative.html",
      },
    ],
  },
  {
    label: "Isolation",
    links: [
      {
        link:
          "https://www.bbc.com/future/article/20200414-how-to-maintain-relationships-in-self-isolation",
      },
      {
        link:
          "https://www.breakthecycle.org/blog/warning-signs-spotlight-isolation",
      },
    ],
  },
  {
    label: "Poor Communication",
    links: [
      {
        link:
          "https://www.mindbodygreen.com/0-14106/5-communication-mistakes-that-kill-relationships.html#:~:text=Couples%20that%20don't%20learn,%2C%20conflict%2C%20and%20relational%20growth.&text=Lack%20of%20communication%20in%20relationships,t%20communicate%20with%20your%20partner.",
      },
      {
        link: "https://positivepsychology.com/communication-in-relationships/",
      },
    ],
  },
  {
    label: "Economic Burden",
    links: [
      {
        link:
          "https://www.psychologytoday.com/us/blog/so-happy-together/201805/sometimes-lack-money-is-the-root-relationship-evil",
      },
      {
        link:
          "https://m.economictimes.com/wealth/plan/10-financial-warning-signs-to-watch-out-for-when-in-a-relationship/articleshow/74148081.cms",
      },
    ],
  },
  {
    label: "Coercion",
    links: [
      {
        link:
          "https://www.theguardian.com/society/2019/jan/21/this-is-not-love-victim-of-coercive-control-says-she-saw-red-flags-from-start",
      },
      {
        link:
          "https://www.brethertons.co.uk/site/blog/men-in-controlling-coercive-relationships",
      },
    ],
  },
  {
    label: "Gaslighting",
    links: [
      {
        link:
          "https://www.vox.com/first-person/2018/12/19/18140830/gaslighting-relationships-politics-explained",
      },
      {
        link:
          "https://www.thrivingcenterofpsych.com/blog/how-gaslighting-can-burn-down-a-relationship",
      },
    ],
  },
  {
    label: "Rape",
    links: [
      {
        link: "https://youngmenshealthsite.org/guides/sexual-consent/",
      },
      {
        link:
          "https://www.dayoneri.org/sites/default/files/site-content/pdfs/10%20Things%20men%20can%20do%20to%20stop%20rape.pdf",
      },
    ],
  },
  {
    label: "Emotional labor",
    links: [
      {
        link:
          "https://thebodyisnotanapology.com/magazine/7-ways-men-must-learn-to-do-emotional-labor-in-their-relationships/",
      },
      {
        link: "https://qz.com/work/1228492/the-mens-guide-to-emotional-labor/",
      },
    ],
  },
  {
    label: "Lying",
    links: [
      {
        link:
          "https://www.psychologytoday.com/us/blog/fixing-families/201707/lying-in-relationships-3-steps-making-it-stop",
      },
      {
        link: "https://www.couplesinstitute.com/why-we-lie/",
      },
    ],
  },
];

const SuggestionBtn = (props: any) => {
  const suggestionBtnRef = useRef<HTMLAnchorElement>(null);
  const handleClick = () => {
    props.addSuggestedLinks(props.linkData);
  };

  useEffect(() => {
    if (suggestionBtnRef.current) {
      suggestionBtnRef.current.style.display = "inline-block";
      TweenMax.fromTo(
        suggestionBtnRef.current,
        0.1,
        { transform: "scale(0.1)" },
        { transform: "scale(1)" }
      );
    }
  }, [TweenMax]);

  return (
    <a
      ref={suggestionBtnRef}
      className="educational-prompt-suggestion-btn"
      onClick={handleClick}
      style={{ display: "none" }}
    >
      {props.label}
    </a>
  );
};

const Link = (props: any) => {
  const [url, setUrl] = useState(props.url);
  const handleInputChange = (evt: any) => {
    setUrl(evt.target.value);
  };
  const handleRemove = (evt: any) => {
    props.removeSelf(props.index);
  };

  useEffect(() => {
    if (props.url != url) {
      setUrl(props.url);
    }
  }, [props]);

  return (
    <div className="educational-prompt-list-entry">
      <div className="educational-prompt-list-entry-thumbnail">
        <img src="https://upload.wikimedia.org/wikipedia/commons/7/74/Globe_icon_2014-06-26_22-09.png" />
      </div>
      <div className="educational-prompt-list-entry-url">
        <input
          placeholder="Add the resource URL here!"
          value={url}
          onChange={handleInputChange}
          className={isValidUrl(url) ? "valid-input" : ""}
        />
      </div>
      <div
        className="educational-prompt-list-entry-btn-group"
        onClick={handleRemove}
      >
        <span>✕</span>
      </div>
    </div>
  );
};
const LinkList = (props: any) => {
  return (
    <div>
      {props.data.map((entry: any, index: any) => (
        <Link
          url={entry.link}
          removeSelf={props.removeBoxAtIndex}
          index={index}
        />
      ))}
    </div>
  );
};

const AddLinkBtn = (props: any) => {
  const addLinkBtnRef = useRef<HTMLAnchorElement>(null);
  const handleClick = () => {
    props.addNewUrlPlaceholder();
  };
  useEffect(() => {
    if (addLinkBtnRef.current) {
      addLinkBtnRef.current.style.display = "inline-block";
      TweenMax.fromTo(
        addLinkBtnRef.current,
        0.5,
        { transform: "scale(0.1)" },
        { transform: "scale(1)" }
      );
    }
  }, [TweenMax]);

  return (
    <a
      ref={addLinkBtnRef}
      className={
        "educational-prompt-add-link-btn " +
        (props.attention ? "attention" : "")
      }
      onClick={handleClick}
      style={{ display: "none" }}
    >
      Add more!
    </a>
  );
};

const ContinueBtn = (props: any) => {
  return (
    <a
      className={
        "educational-prompt-continue-btn " +
        (!props.isReadyToSubmit ? "skippable" : "")
      }
      onClick={props.handleSubmit}
    >
      {props.isReadyToSubmit ? "Continue" : "Skip"}
    </a>
  );
};

const EducationalPrompt = (props: any) => {
  const getReadyState = () => {

    return linkListData.reduce(
      (gross: boolean, curr: any) => gross || isValidUrl(curr.link),
      false
    );
  };

  const [linkListData, setLinkListData]: any = useState([]);
  const [isReadyToSubmit, setIsReadyToSubmit]: any = useState(getReadyState());

  useEffect(() => {
    setIsReadyToSubmit(getReadyState());
  }, [linkListData]);

  const addNewUrlPlaceholder = () => {
    setLinkListData([...linkListData, { url: "" }]);
  };

  const addSuggestedLinks = (newData: any) => {
    setLinkListData([...linkListData, ...newData]);
  };
  const removeBoxAtIndex = (index: number) => {
    const newLinkListData = linkListData.filter(
      (_: any, link_index: any) => link_index != index
    );
    setLinkListData(newLinkListData);
  };

  const navigateToNextScreen = () => {
    props.setNavigatorData({
      ...props.navigatorData,
      links: linkListData,
    });
    props.setNavigatorIndex(4);
  };

  const educationalPromptRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (educationalPromptRef.current) {
      educationalPromptRef.current.style.display = "block";
      TweenMax.fromTo(
        educationalPromptRef.current,
        0.5,
        {
          transform: "scale(0.9)",
          opacity: 1,
          clipPath: "circle(80% at center)",
        },
        {
          transform: "scale(1)",
          opacity: 1,
          clipPath: "circle(100% at center)",
        }
      );
    }
  }, [TweenMax]);
  return (
    <div className="educational-prompt-container">
      <div
        ref={educationalPromptRef}
        className="educational-prompt"
        style={{ display: "none" }}
      >
        <div className="educational-prompt-header">
          <div>
            <span className="educational-prompt-header-emoji">❤️️🤝❤️</span>
          </div>
          <div>
            <span className="educational-prompt-header-desc">
              We learn and unlearn the most in our most vulnerable moments. Take
              out time to attach resources. Let's be all better, and let all be
              better together.
            </span>
          </div>
          <div className="educational-prompt-header-suggestions">
            {DummySuggestionsData.map((entry: any) => (
              <SuggestionBtn
                label={entry.label}
                linkData={entry.links}
                addSuggestedLinks={addSuggestedLinks}
              />
            ))}
            <AddLinkBtn
              addNewUrlPlaceholder={addNewUrlPlaceholder}
              attention={!isReadyToSubmit}
            />
          </div>
        </div>
        <div className="educational-prompt-body">
          <LinkList data={linkListData} removeBoxAtIndex={removeBoxAtIndex} />
        </div>
        <div className="educational-prompt-footer">
          <ContinueBtn
            isReadyToSubmit={isReadyToSubmit}
            handleSubmit={navigateToNextScreen}
          />
        </div>
      </div>
    </div>
  );
};

export default EducationalPrompt;
