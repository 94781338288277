import React, { useState, useRef, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { TweenMax } from "gsap";
import { Letter, retrieveLetter } from "../core/letter";
import { FirebaseApp } from "../core/firebase";

const LetterReason = (props: any) => {
  const letterReasonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (letterReasonRef.current) {
      letterReasonRef.current.style.display = "flex";
      TweenMax.fromTo(
        letterReasonRef.current,
        0.1,
        { transform: "scale(0.1)", opacity: 0 },
        { transform: "scale(1)", opacity: 1 }
      );
    }

    return () => {
      if (letterReasonRef.current) {
        TweenMax.fromTo(
          letterReasonRef.current,
          0.5,
          { transform: "scale(1)", opacity: 1 },
          { transform: "scale(0.1)", opacity: 0 }
        );
      }
    };
  }, [letterReasonRef]);

  return (
    <div className="letter-reason-container">
      <div
        ref={letterReasonRef}
        className="letter-reason"
        style={{ display: "none" }}
      >
        <div className="letter-reason-inner">
          <h3>{props.header}</h3>
          <p>{props.paragraph}</p>
        </div>
      </div>
    </div>
  );
};

const Link = (props: any) => {
  return (
    <li className="letter-reader-link">
      <a href={props.url}>{props.url}</a>
    </li>
  );
};

const Spinner = () => {
  return (
    <div className="loadingio-spinner-rolling-92gbw0a1ack">
      <div className="ldio-vm6kcz5uvjm">
        <div></div>
      </div>
    </div>
  );
};

const LetterReader = (props: any) => {
  const { letterId } = useParams();
  const [data, setData] = useState<any>();
  //   const data = DummyData[letterId];

  const fetchDataAPI = async () => {
    const response = await retrieveLetter(letterId);
    if (response.status == 200) {
      setData(response.data);
    }
  };

  useEffect(() => {
    fetchDataAPI();
  }, []);

  const letterReaderRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (letterReaderRef.current != null) {
      letterReaderRef.current.style.display = "block";
      TweenMax.fromTo(
        letterReaderRef.current,
        0.5,
        { transform: "scale(0.8)", opacity: 1 },
        { transform: "scale(1)", opacity: 1 }
      );
    }
  }, [TweenMax]);

  const navigatorData = props.navigatorData;
  return (
    <div className="letter-reader-container">
      <div
        ref={letterReaderRef}
        className={"letter-reader " + (data ? "" : "loading")}
        style={{ display: "none" }}
      >
        {data ? (
          <>
            <div className="letter-reader-desc">
              <div className="letter-reader-emoji-container">
                <span>{data.emoji}</span>
              </div>
              <div className="letter-reader-label">
                <p>{data.description}</p>
              </div>
            </div>
            <div className="letter-reader-library">
              {data.reasons.map((data: any, index: any) => (
                <LetterReason
                  header={data.header}
                  paragraph={data.paragraph}
                  index={index}
                  key={index}
                />
              ))}
            </div>
            <div>
              <h1 className="letter-reader-linklist-heading">
                Links to educate you
              </h1>
              <ul>
                {data.links.map((entry: any, index: number) => (
                  <Link url={entry.link} key={index} />
                ))}
              </ul>
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
      {props.children}
    </div>
  );
};

export default LetterReader;
