export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBAf6kWDXrAHDH3sa1AaoxftTjDThZ8iwk",
  authDomain: "the-boy-fairy.firebaseapp.com",
  databaseURL: "https://the-boy-fairy.firebaseio.com",
  projectId: "the-boy-fairy",
  storageBucket: "the-boy-fairy.appspot.com",
  messagingSenderId: "1009918426360",
  appId: "1:1009918426360:web:b53107b1ced290ef6b8f0d",
  measurementId: "G-16JCGLNF5Q",
};
