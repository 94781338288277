import React, { useEffect, useRef } from "react";
import { TweenMax } from "gsap";
import { isNull } from "util";

const Categories = [
  {
    label: "I want to break up with you because…",
    description:
      "I want boyfairy to tell you that I am breaking up with you because…",
    emoji: "👼",
  },
  {
    label: "I am blocking you because…",
    description: "I want boyfairy to tell you that I am blocking you because…",
    emoji: "🙃",
  },
  {
    label: "You should know this about me…",
    description: "I want boyfairy to tell you something that you should know…",
    emoji: "🥺",
  },
  {
    label: "Something else",
    description:
      "I want boyfairy to tell you something that I have been meaning to tell…",
    emoji: "🥰",
  },
];

const CategoryButton = (props: any) => {
  return (
    <div className="category-btn-container">
      <a className="category-btn" onClick={props.onSelect}>
        <span className="category-emoji">{props.emoji}</span>
        <span className="category-label">{props.label}</span>
      </a>
    </div>
  );
};

const CategorySelector = (props: any) => {
  const handleSelection = (
    label: string,
    description: string,
    emoji: string
  ) => {
    props.setNavigatorData({
      label,
      description,
      emoji,
    });
    props.setNavigatorIndex(2);
  };

  const categorySelectorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (categorySelectorRef.current != null) {
      categorySelectorRef.current.style.display = "block";
      TweenMax.fromTo(
        categorySelectorRef.current,
        0.5,
        {
          transform: "scale(0.1)",
          opacity: 0.5,
          clipPath: "circle(0% at center)",
        },
        {
          transform: "scale(1)",
          opacity: 1,
          clipPath: "circle(100% at center)",
        }
      );
    }
  }, [TweenMax]);

  return (
    <div className="category-selector-wrapper">
      <div
        ref={categorySelectorRef}
        className="category-selector"
        style={{ display: "none" }}
      >
        <div className="category-selector-desc">
          <p>
            Boyfairy helps you communicate when you don’t have the energy to
          </p>
        </div>
        <div className="category-btn-group">
          {Categories.map((category, index) => (
            <CategoryButton
              key={index}
              label={category.label}
              description={category.description}
              emoji={category.emoji}
              onSelect={() =>
                handleSelection(
                  category.label,
                  category.description,
                  category.emoji
                )
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default CategorySelector;
